<script>
import Base from "@backend/Base.vue";
import BOGen from "@helper/BOGen";
import Gen from "@helper/Gen";
import draggable from "vuedraggable";
export default {
  name: "BoNews",
  extends: Base,
  components: {
    draggable,
  },
  computed: {
    filterStatus: {
      get() {
        return this.filter.status;
      },
      set(newValue) {
        this.filter = {
          ...this.filter,
          status: newValue
        };
        this.search();
      }
    },
    filterCategory: {
      get() {
        return this.filter.category;
      },
      set(newValue) {
        this.filter = {
          ...this.filter,
          category: newValue
        };
        this.search();
      }
    }
  },
  data() {
    return {
      Name: "BoNews",
      status: {},
      webSettings: {},
      titleLength: 0,
      metatitleLength: 0,
      maxmetatitleLength: 60,
      descLength: 0,
      metadescLength: 0,
      maxmetadescLength: 160,
      heroImage: {},
      newsHeroImage: "",
      data: [],
      newsCategory: {},
      dataLength: 0,
      category: [],
    };
  },
  mounted() {
    this.$set(this.$root, "page", this);
    this.refreshData(() => {
      this.$set(
        this.$root.page,
        "ObjectName",
        this.replacePageTitle("Berita & Artikel", "Berita & Artikel")
      );
    });
    this.moduleRedirect();
    if(this.$route.params.id !== "add"){
      setTimeout(()=>{
        $("#an_tags input").removeAttr("placeholder")
        $("#an_meta_keyword input").removeAttr("placeholder")
      },500)
    }
  },
  methods: {
    checkMove(evt, originalEvent) {
      $("html,body").animate({scrollTop:$(originalEvent.toElement).offset().top-100}, 300)
    },
    endDrag() {
      BOGen.apirest(
        "/" + this.Name,
        {
          data: this.data,
          type: "sort",
        },
        (err, resp) => {
        },
        "POST"
      );
    },
    sort() {
      this.filter.sort = this.filter.sort == "all" ? "" : "all";
      if (this.filter.sort == "all") {
        this.filter.status = "";
        this.filter.search = "";
        this.filter.category = "";
      }
      var query = this.filter;
      delete query.page;
      this.$router.push({
        name: this.Name,
        query: query,
      });
    },
    submitView() {
      BOGen.apirest(
        "/" + this.Name,
        {
          data: this.webSettings,
          type: "updateView",
        },
        (err, resp) => {
          if (resp.error)
            return Gen.info(resp.message, resp.style, 3000, ".info");
          if (resp.success) {
            Gen.info(resp.message, resp.style, 3000, ".info");
            this.refreshData();
            setTimeout(()=>{
              this.$router.push({name: this.Name})
            },1000)
          }
        },
        "POST"
      );
    },
    editHeroImage(v) {
      $("#modalNewsImg").modal();
      this.newsHeroImage = v;
    },
    submitHeroImage(e) {
      BOGen.apirest(
        "/" + this.Name,
        {
          type: "updateHero",
          heroImage: {
            as_val: {
              img: this.heroImage.as_val.img,
              img_mobile: this.heroImage.as_val.img_mobile,
              alt_img: this.heroImage.as_val.alt_img,
              alt_img_mobile: this.heroImage.as_val.alt_img_mobile
            },
          },
        },
        (err, resp) => {
          if (e) e.btnUnloading();
          if (resp.error)
            return Gen.info(resp.message, "danger", 3000, ".kr-img-info");
          if (resp.success) {
            Gen.info(resp.message, "success", 2000, ".kr-img-info");
            setTimeout(() => {
              $("#modalNewsImg").modal("hide");
            }, 500);
            this.refreshData();
          }
        },
        "POST"
      );
    },
    handleSubmitForm() {
      if (!this.row.an_desc) {
        return $("[error='an_desc']").html("<span class='error'>Input Isi Konten</span>");
      }
      if (!this.row.an_img) {
        return $("[error='an_img']").html("<span class='error'>Input Image</span>");
      }

      this.submitForm();
    },
  },
  watch: {
    "$route.params"() {
      this.$set(
        this.$root.page,
        "ObjectName",
        this.replacePageTitle("Berita & Artikel", "Berita & Artikel")
      );
      if(this.$route.params.id !== "add"){
        $("#an_tags input").removeAttr("placeholder")
        $("#an_meta_keyword input").removeAttr("placeholder")
      }
    },
    "$route.query"() {
      if (this.$route.params.id) {
        this.$set(this.row, "status", this);
        this.$set(this.row, "search", this);
        this.$set(this.row, "page", this);
        setTimeout(() => {
          this.row.status = this.$route.query.status;
          this.row.search = this.$route.query.search;
          this.row.page = this.$route.query.page;
        }, 500);
      }
      this.refreshData();
    },
    "row.an_title"(v) {
      this.titleLength = v.length;
      if(this.$route.params.id == "add"){
        if(this.titleLength < 61){
          this.metatitleLength = v.length;
          this.row.an_meta_title = v
        }
      }
      this.row.an_slug = v
        .toLowerCase()
        .replace(/ /g, "-")
        .replace(/[^\w-]+/g, "");
      this.row.an_alt_img = v;
      if (v.length > 0) {
        if (v.length < 3) {
          $("#an_alt_img-error").text('Input min 3 characters');
          $("#an_meta_title-error").text('Input min 3 characters');
        } else {
          $("#an_alt_img-error").css('display', 'none');
          $("[name='an_alt_img']").removeClass('error');
          $("#an_alt_img-error").text('');
          $("#an_meta_title-error").css('display', 'none');
          $("[name='an_meta_title']").removeClass('error');
          $("#an_meta_title-error").text('');
        }
      }
    },
    "row.an_tags"(v){
      if (v.length > 0) {
        $("#an_tags-error").css('display', 'none');
        $("#an_meta_keyword-error").css('display', 'none');
        $("#an_tags-error").text('');
        $("#an_meta_keyword-error").text('');
      }

      if(v.length) $("#an_tags input").removeAttr("placeholder")
      else $("#an_tags input").attr("placeholder","e.g. Al Quran")
      
      if(this.$route.params.id == "add"){
        this.row.an_meta_keyword = v
        if(this.v.length) $("#an_meta_keyword input").removeAttr("placeholder")
        else $("#an_meta_keyword input").attr("placeholder","e.g. Al Quran")
      }
    },
    "row.an_slug"(v) {
      this.row.an_slug = v
        .toLowerCase()
        .replace(/ /g, "-")
        .replace(/[^\w-]+/g, "");
    },
    "row.an_meta_title"(v) {
      this.metatitleLength = v.length;
    },
    "row.an_meta_keyword"(v){
      if(v.length) $("#an_meta_keyword input").removeAttr("placeholder")
      else $("#an_meta_keyword input").attr("placeholder","e.g. Al Quran")
    },
    "row.an_meta_desc"(v) {
      this.metadescLength = v.length;
      if(v.length > 160){
        $(".info-meta-desc").removeClass("text-info").addClass("error").html("Input max 160 characters.")
      }
      else{
        $(".info-meta-desc").addClass("text-info").html(`${this.maxmetadescLength - v.length} character remaining`)
      }
    },
    "row.an_desc"(v) {
      if (v.length > 0) {
        $("[error='an_desc']").html("");
      }
      if(this.$route.params.id == "add" && v.replace(/<[^>]*>?/gm, '').length <= 161 ){    
        this.row.an_meta_desc = v.replace(/<[^>]*>?/gm, '')
        this.metadescLength = v.replace(/<[^>]*>?/gm, '').length;
      }
    },
    "row.an_img"(v) {
      if (v) {
        $("[error='an_img']").html("");
      }
    }
  },
};
</script>
<template>
  <div class="container-fluid">
    <PageTitle></PageTitle>
    <div class="row" v-if="!$route.params.id">
      <div class="col-md-12">
        <div class="card">
          <div class="card-header">
            <h5 class="card-title">
              Hero Image Berita & Artikel
            </h5>
          </div>
          <div class="card-body">
          <div class="kr-img-info"></div>
            <VForm @resp="submitHeroImage" method="post">
              <div class="row">
                <div class="struktur-info col-12"></div>
                <div v-if="moduleRole('Edit') && !$route.params.id" class="col-md-12">
                  <div class="row">
                    <div class="col-md-4">
                      <BoField name="image" class="mb-0" :label="'Hero Image'">
                        <Uploader
                          name="heroImage"
                          :param="{ thumbnail: true }"
                          type="hero"
                          uploadType="cropping"
                          v-model="heroImage.as_val.img"
                          :deleted="false"
                          :squarePreview="true"
                        ></Uploader>
                      </BoField>
                    </div>
                    <div class="col-md-4">
                      <BoField name="image" class="mb-0" :label="'Hero Image Mobile'">
                        <Uploader
                          name="heroImage"
                          :param="{ thumbnail: true }"
                          type="heromobile"
                          uploadType="cropping"
                          v-model="heroImage.as_val.img_mobile"
                          :deleted="false"
                          :squarePreview="true"
                        ></Uploader>
                      </BoField>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-md-4">
                      <BoField
                        mandatory
                        name="alt_img"
                        v-model="heroImage.as_val.alt_img"
                        :attr="{
                          type: 'text',
                          placeholder: 'e.g. Hero Image Berita dan Artikel'
                        }"
                        :label="'Alt Image'"
                        class="mt-3"
                        required=""
                      ></BoField>
                    </div>
                    <div class="col-md-4">
                      <BoField
                        mandatory
                        name="alt_img_mobile"
                        v-model="heroImage.as_val.alt_img_mobile"
                        :attr="{
                          type: 'text',
                          placeholder: 'e.g. Hero Image Berita dan Artikel'
                        }"
                        :label="'Alt Image Mobile'"
                        class="mt-3"
                        required=""
                      ></BoField>
                    </div>
                  </div>
                </div>
                <div v-else class="col-md-8">
                  <img
                    :src="uploader(heroImage.as_val.img)"
                    :alt="heroImage.as_val.alt_img"
                  />
                </div>
                <div class="col-12 mt-3 text-right">
                  <button
                    v-if="moduleRole('Edit')"
                    type="submit"
                    class="btn btn-rounded btn-loading btn-info"
                  >
                    Perbarui
                  </button>
                </div>
              </div>
            </VForm>

          </div>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-12" v-if="!$route.params.id">
        <div class="card mb-2">
          <div class="card-header">
            <h5 class="card-title">Berita &amp; Artikel</h5>
          </div>
          <div class="card-body ">
            <div class="info"></div>
            <VForm @resp="submitView" class="row align-items-center">
              <div class="col-6">
                <BoField name="as_val" class="mb-0" :label="'Tampilkan berdasarkan'">
                  <radio
                    name="as_val"
                    v-model="webSettings.as_val"
                    option="P"
                    :attr="validation('as_val')"
                    >Create Date
                  </radio>
                  <radio name="as_val" v-model="webSettings.as_val" option="S"
                    >Sorting</radio
                  >
                </BoField>
              </div>
              <div class="col-6 text-right">
                <button
                  v-if="moduleRole('Edit')"
                  type="submit"
                  class="fcbtn btn btn-info btn-rounded btn-outline btn-1e btn-loading"
                >
                  Perbarui
                </button>
              </div>
            </VForm>
          </div>
        </div>
      </div>
      <div class="col-12" v-if="!$route.params.id">
        <div class="card">
          <div class="card-header">
            <VForm @resp="search">
              <div class="row align-items-center justify-content-between gutter-10">
                <div class="col-md-2">
                  <div class="col-sm-12 col-6">
                    <h5 class="card-title mb-0">Daftar Berita & Artikel</h5>
                  </div>
                </div>
                <div class="col-md-auto">
                  <div class="row justify-content-end align-items-center">
                    <div v-if="dataLength > 0" class="col-md-1 col-lg-auto col-sm-auto">
                      <router-link class="btn btn-success" :to="{query: {sortall: true}}">
                        <i class="fas fa-sort"></i> Lihat Semua
                      </router-link>
                    </div>
                    <div
                      v-if="dataLength > 0"
                      class="ml-sm-auto ml-lg-0 col-lg-auto col-sm-4"
                    >
                      <select2 :options="status" v-model="filterStatus">
                        <option value="">-- Pilih Status --</option>
                      </select2>
                    </div>
                    <div
                      v-if="dataLength > 0"
                      class="ml-sm-auto ml-lg-0 col-lg-auto col-sm-4"
                    >
                      <select2 v-model="filterCategory">
                        <option value="">-- Pilih Kategori --</option>
                        <option
                          v-for="(c, k) in category"
                          :key="k"
                          :value="c.anc_category"
                        >
                        {{c.anc_type === "1" ? "Artikel" : "Berita"}} - {{ c.anc_category }}
                        </option>
                      </select2>
                    </div>
                    <div v-if="dataLength > 0" class="col-md-auto col-sm-4">
                      <div class="form-group mb-0">
                        <div class="input-group">
                          <input
                            type="text"
                            v-model="filter.search"
                            v-on:keyup.enter="search"
                            class="form-control"
                            placeholder="Cari..."
                          />
                          <div class="input-group-append">
                            <button
                              class="btn btn-info"
                              type="button"
                              @click="search()"
                            >
                              <i class="fas fa-search"></i>
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div v-if="dataLength > 0" class="col-md-auto col-sm-auto">
                      <router-link
                        :to="{ name: Name }"
                        class="btn btn-block btn-warning"
                        >Reset</router-link
                      >
                    </div>
                  </div>
                </div>
              </div>
            </VForm>
          </div>
          <div class="card-body">
            <div class="row" v-if="!$route.params.id">
              <div class="col-12">
                <draggable
                  v-if="moduleRole('Edit')"
                  v-model="data.data"
                  tag="div"
                  class="row gutter-20"
                  :move="checkMove"
                  @end="endDrag"
                >
                  <div
                    class="col-lg-3 col-md-4"
                    v-for="(v, k) in data.data"
                    :key="k"
                  >
                    <div class="item-list collection">
                      <div class="row text-center">
                        <div class="col-md-12">
                          <div class="product-img recipe-cat mb-2" style="cursor:move;">
                            <img :src="uploader(v.an_img, '250')" alt="prod" />
                            <div class="bullet-cta">
                              <router-link
                                v-if="moduleRole('Edit')"
                                class="bullet_change_status bg-warning"
                                :to="{
                                  name: Name,
                                  params: { id: v.id },
                                }"
                                v-tooltip="'Ubah'"
                                ><i class="ti-marker-alt"></i
                              ></router-link>
                              <a
                                v-if="moduleRole('Delete')"
                                href="javascript:;"
                                class="bullet_delete"
                                @click="deleteItem($event, k)"
                                v-tooltip="'Hapus'"
                                ><i class="ti-trash"></i
                              ></a>
                            </div>
                            <label class="mt-2 text-white py-1 rounded px-2 mx-1 label-info">{{
                              v.anc_category
                            }}</label>
                            <label
                              class="mt-2 text-white py-1 rounded px-2 mx-1 label-warning"
                              v-if="v.an_is_active == 'A'"
                              >Archive</label
                            >
                            <label
                              class="mt-2 text-white py-1 rounded px-2 mx-1 label-info"
                              v-if="v.an_is_active == 'D'"
                              >Draft</label
                            >
                            <label
                              class="mt-2 text-white py-1 rounded px-2 mx-1 label-success"
                              v-if="v.an_is_active == 'P'"
                              >Publish</label
                            >
                          </div>
                          <h5
                            class="card-title mt-1"
                            v-tooltip="v.an_title.limitChar(40).endsWith('...') ? v.an_title : ''"
                          >
                            {{
                              v.an_title.limitChar(40)
                            }}
                          </h5>
                          <small>{{
                            (v.an_publish_date || "").dates("format")
                          }}</small>
                        </div>
                      </div>
                    </div>
                  </div>
                </draggable>
                <div v-else class="row gutter-20">
                  <div
                    class="col-lg-3 col-md-4"
                    v-for="(v, k) in data.data"
                    :key="k"
                  >
                    <div class="item-list collection">
                      <div class="row text-center">
                        <div class="col-md-12">
                          <div class="product-img recipe-cat mb-2">
                            <img :src="uploader(v.an_img, '250')" alt="prod" />
                            <div class="bullet-cta">
                              <router-link
                                v-if="moduleRole('Edit')"
                                class="bullet_change_status bg-warning"
                                :to="{
                                  name: Name,
                                  params: { id: v.id }
                                }"
                                v-tooltip="'Ubah'"
                                ><i class="ti-marker-alt"></i
                              ></router-link>
                              <a
                                v-if="moduleRole('Delete')"
                                href="javascript:;"
                                class="bullet_delete"
                                @click="deleteItem($event, k)"
                                v-tooltip="'Hapus'"
                                ><i class="ti-trash"></i
                              ></a>
                            </div>
                            <label class="mt-2 text-white py-1 rounded px-2 mx-1 label-info">{{
                              v.anc_category
                            }}</label>
                            <label
                              class="mt-2 text-white py-1 rounded px-2 mx-1 label-warning"
                              v-if="v.an_is_active == 'A'"
                              >Archive</label
                            >
                            <label
                              class="mt-2 text-white py-1 rounded px-2 mx-1 label-info"
                              v-if="v.an_is_active == 'D'"
                              >Draft</label
                            >
                            <label
                              class="mt-2 text-white py-1 rounded px-2 mx-1 label-success"
                              v-if="v.an_is_active == 'P'"
                              >Publish</label
                            >
                          </div>
                          <h5
                            class="card-title mt-1"
                            v-tooltip="v.an_title.limitChar(40).endsWith('...') ? v.an_title : ''"
                          >
                            {{
                              v.an_title.limitChar(40)
                            }}
                          </h5>
                          <small>{{
                            (v.an_publish_date || "").dates("format")
                          }}</small>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div v-if="NotFound" class="text-center col-md-12 mt-3">
                  <h5 class="tc">{{ NotFound }}</h5>
                </div>
                <div
                  v-if="data.data === false"
                  class="text-center col-md-12 mt-3"
                >
                  <LoadingSpinner light></LoadingSpinner>
                </div>
              </div>
            </div>
            <Pagination
              v-if="!$route.query.sortall"
              class="justify-content-end mt-3 mb-0"
              :data="data"
              :limit="3"
              @pagination-change-page="onPaging"
            ></Pagination>
          </div>
        </div>
      </div>
      <div
        class="col-12"
        v-if="$route.params.id && (moduleRole('Add') || moduleRole('Edit'))"
      >
        <div class="card">
          <VForm @resp="handleSubmitForm" method="post">
            <div class="card-body">
              <div class="row mb-3">
                <div class="col-md-8">
                  <h5 class="card-title">
                    {{ row.id ? "Ubah" : "Tambah" }} {{ ObjectName }}
                  </h5>
                </div>
              </div>
              <div class="info"></div>
              <div class="row">
                <div class="col-md-7">
                  <BoField name="an_cat" :label="'Kategori'">
                    <select2
                      name="an_cat"
                      v-bind="validation('an_cat')"
                      :options="newsCategory"
                      :object="['anc_id', 'anc_category']"
                      v-model="row.an_cat"
                    >
                      <option value="">-- Pilih Kategori --</option>
                    </select2>
                  </BoField>
                  <BoField
                    class="mb-0"
                    name="an_title"
                    v-model="row.an_title"
                    :attr="{placeholder: 'e.g. Cara menghafal Al-Quran dengan baik ...', maxLength: 60}"
                    :label="'Judul'"
                    required=""
                  ></BoField>
                  <small class="mb-2 d-inline-block text-info"
                    >{{ validation("an_title").maxlength - titleLength }}
                    character remaining
                  </small>
                  <BoField name="an_desc" mandatory class="mb-0" :label="'Isi Konten'">
                    <CKEditor
                      name="an_desc"
                      v-model="row.an_desc"
                      v-bind="validation('an_desc')"
                    ></CKEditor>
                  </BoField>
                </div>
                <div class="col-md-5">
                  <BoField name="an_img" class="mb-0">
                    <Uploader
                      name="an_img"
                      type="news"
                      uploadType="cropping"
                      :deleted="false"
                      :param="{ thumbnail: true }"
                      v-model="row.an_img"
                    ></Uploader>
                  </BoField>

                  <BoField
                    mandatory
                    name="an_alt_img"
                    v-model="row.an_alt_img"
                    :attr="{
                      type: 'text',
                      placeholder: 'e.g. Image Cara Menghafal...'
                    }"
                    :label="'Alt Image'"
                    required=""
                  ></BoField>

                  <BoField name="an_author" v-model="row.an_author" :label="'Author'" required="" :attr="{placeholder: 'e.g. SDI Al Hidayah'}"></BoField>
                  <BoField name="an_tags" mandatory>
                    <TagsInput
                      id="an_tags"
                      name="an_tags"
                      v-model="row.an_tags"
                      :attr="validation('an_tags')"
                      :placeholder="'Ketik dan enter'"
                    ></TagsInput>
                  </BoField>
                  <BoField name="an_publish_date" :label="'Tanggal Terbit'">
                    <DatePicker
                      name="an_publish_date"
                      v-model="row.an_publish_date"
                      v-bind="validation('an_publish_date')"
                    ></DatePicker>
                  </BoField>
                  <BoField name="an_is_active">
                    <radio
                      name="an_is_active"
                      v-model="row.an_is_active"
                      option="D"
                      :attr="validation('an_is_active')"
                    >
                      Draft</radio
                    >
                    <radio
                      name="an_is_active"
                      v-model="row.an_is_active"
                      option="P"
                      >Publish</radio
                    >
                    <radio
                      name="an_is_active"
                      v-model="row.an_is_active"
                      option="A"
                      >Archive</radio
                    >
                  </BoField>
                </div>
                <div class="col-12">
                  <hr />
                  <h5 class="card-title mb-3">Meta Content</h5>
                  <div class="row">
                    <div class="col-sm-6">
                      <BoField
                        class="mb-0"
                        name="an_meta_title"
                        info="Meta title adalah judul yang muncul di halaman hasil pencarian. Tips: Jumlah meta title maksimal 60 karakter."
                        :attr="{ minlength: '3', placeholder: 'e.g. Cara menghafal Al-Quran dengan baik...' }"
                        v-model="row.an_meta_title"
                        mandatory
                        required=""
                      ></BoField>
                      <small class="mb-2 d-inline-block text-info"
                        >{{ maxmetatitleLength - metatitleLength }} character
                        remaining
                      </small>
                      <BoField info="Meta keywords berisi informasi kata kunci yang dibutuhkan untuk memberi tahu mesin pencari mengenai isi sebuah halaman website. Umumnya ditulis dalam huruf kecil, dan dipisahkan dengan koma." name="an_meta_keyword" mandatory>
                        <TagsInput
                          id="an_meta_keyword"
                          name="an_meta_keyword"
                          v-model="row.an_meta_keyword"
                          :attr="validation('an_meta_keyword')"
                          :placeholder="'Ketik dan enter'"
                        ></TagsInput>
                      </BoField>
                    </div>
                    <div class="col-sm-6">
                      <BoField class="mb-0" info="Meta description adalah sebuah ringkasan konten website yang muncul di halaman hasil pencarian. Meta description ini terletak di bawah meta title." name="an_meta_desc" mandatory>
                        <textarea
                          name="an_meta_desc"
                          rows="5"
                          :attr="{ minlength: '10' }"
                          v-model="row.an_meta_desc"
                          v-bind="validation('an_meta_desc')"
                          class="form-control"
                          placeholder="e.g. Ide Pokok"
                          required=""
                        ></textarea>
                      </BoField>
                      <small class="mb-2 d-inline-block text-info info-meta-desc"></small>
                    </div>
                  </div>
                </div>
                <div class="col-sm-12">
                  <div class="text-right">
                    <router-link
                      :to="{name: $route.name}"
                      class="fcbtn btn btn-light btn-outline btn-1e btn-rounded mr-2"
                    >
                      Kembali
                    </router-link>
                    <button
                      type="submit"
                      class="fcbtn btn btn-info btn-outline btn-1e btn-loading btn-rounded"
                    >
                      {{ row.id ? "Ubah" : "Tambah" }}
                      <i class="icon-arrow-right14 position-right"></i>
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </VForm>
        </div>
      </div>
    </div>
  </div>
</template>
